export const TypographyTheme = `
  html {
    font-family: sans-serif;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%
  }

  body {
    margin: 0
  }

  article,
  aside,
  details,
  figcaption,
  figure,
  footer,
  header,
  main,
  menu,
  nav,
  section {
    display: block
  }

  audio,
  canvas,
  progress,
  video {
    display: inline-block
  }

  audio:not([controls]) {
    display: none;
    height: 0
  }

  progress {
    vertical-align: baseline
  }

  [hidden],
  template {
    display: none
  }

  a {
    background-color: transparent;
  }

  a:active,
  a:hover {
    outline-width: 0
  }

  abbr[title] {
    border-bottom: none;
    text-decoration: underline;
    text-decoration: underline dotted
  }

  b,
  strong {
    font-weight: inherit;
    font-weight: bolder
  }

  dfn {
    font-style: italic
  }

  h1 {
    font-size: 2em;
    margin: .67em 0
  }

  mark {
    background-color: #ff0;
    color: #000
  }

  small {
    font-size: 80%
  }

  sub,
  sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline
  }

  sub {
    bottom: -.25em
  }

  sup {
    top: -.5em
  }

  img {
    border-style: none
  }

  svg:not(:root) {
    overflow: hidden
  }

  code,
  kbd,
  pre,
  samp {
    font-family: monospace, monospace;
    font-size: 1em
  }

  figure {
    margin: 1em 40px
  }

  hr {
    box-sizing: content-box;
    height: 0;
    overflow: visible
  }

  button,
  input,
  optgroup,
  select,
  textarea {
    font: inherit;
    margin: 0
  }

  optgroup {
    font-weight: 700
  }

  button,
  input {
    overflow: visible
  }

  button,
  select {
    text-transform: none
  }

  [type=reset],
  [type=submit],
  button,
  html [type=button] {
    -webkit-appearance: button
  }

  [type=button]::-moz-focus-inner,
  [type=reset]::-moz-focus-inner,
  [type=submit]::-moz-focus-inner,
  button::-moz-focus-inner {
    border-style: none;
    padding: 0
  }

  [type=button]:-moz-focusring,
  [type=reset]:-moz-focusring,
  [type=submit]:-moz-focusring,
  button:-moz-focusring {
    outline: 1px dotted ButtonText
  }

  fieldset {
    border: 1px solid silver;
    margin: 0 2px;
    padding: .35em .625em .75em
  }

  legend {
    box-sizing: border-box;
    color: inherit;
    display: table;
    max-width: 100%;
    padding: 0;
    white-space: normal
  }

  textarea {
    overflow: auto
  }

  [type=checkbox],
  [type=radio] {
    box-sizing: border-box;
    padding: 0
  }

  [type=number]::-webkit-inner-spin-button,
  [type=number]::-webkit-outer-spin-button {
    height: auto
  }

  [type=search] {
    -webkit-appearance: textfield;
    outline-offset: -2px
  }

  [type=search]::-webkit-search-cancel-button,
  [type=search]::-webkit-search-decoration {
    -webkit-appearance: none
  }

  ::-webkit-input-placeholder {
    color: inherit;
    opacity: .54
  }

  ::-webkit-file-upload-button {
    -webkit-appearance: button;
    font: inherit
  }

  html {
    font: 100%/1.75 'Merriweather', 'Georgia', serif;
    box-sizing: border-box;
    overflow-y: auto;
  }

  * {
    box-sizing: inherit;
  }

  *:before {
    box-sizing: inherit;
  }

  *:after {
    box-sizing: inherit;
  }

  body {
    color: hsla(0, 0%, 0%, 0.9);
    font-family: 'Merriweather', 'Georgia', serif;
    font-weight: 400;
    word-wrap: break-word;
    font-kerning: normal;
    -moz-font-feature-settings: "kern", "liga", "clig", "calt";
    -ms-font-feature-settings: "kern", "liga", "clig", "calt";
    -webkit-font-feature-settings: "kern", "liga", "clig", "calt";
    font-feature-settings: "kern", "liga", "clig", "calt";
  }

  img {
    max-width: 100%;
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    margin-bottom: 1.75rem;
  }

  h1 {
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    margin-bottom: 1.75rem;
    color: inherit;
    font-family: Montserrat, sans-serif;
    font-weight: 900;
    text-rendering: optimizeLegibility;
    font-size: 2.5rem;
    line-height: 1.1;
  }

  h2 {
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    margin-bottom: 1.75rem;
    color: inherit;
    font-family: 'Merriweather', 'Georgia', serif;
    font-weight: 900;
    text-rendering: optimizeLegibility;
    font-size: 1.73286rem;
    line-height: 1.1;
  }

  h3 {
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    margin-bottom: 1.75rem;
    color: inherit;
    font-family: 'Merriweather', 'Georgia', serif;
    font-weight: 900;
    text-rendering: optimizeLegibility;
    font-size: 1.4427rem;
    line-height: 1.1;
  }

  h4 {
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    margin-bottom: 1.75rem;
    color: inherit;
    font-family: 'Merriweather', 'Georgia', serif;
    font-weight: 900;
    text-rendering: optimizeLegibility;
    font-size: 1rem;
    line-height: 1.1;
    letter-spacing: 0.140625em;
    text-transform: uppercase;
  }

  h5 {
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    margin-bottom: 1.75rem;
    color: inherit;
    font-family: 'Merriweather', 'Georgia', serif;
    font-weight: 900;
    text-rendering: optimizeLegibility;
    font-size: 0.83255rem;
    line-height: 1.1;
  }

  h6 {
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    margin-bottom: 1.75rem;
    color: inherit;
    font-family: 'Merriweather', 'Georgia', serif;
    font-weight: 900;
    text-rendering: optimizeLegibility;
    font-size: 0.75966rem;
    line-height: 1.1;
    font-style: italic;
  }

  hgroup {
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    margin-bottom: 1.75rem;
  }

  ul {
    margin-left: 1.75rem;
    margin-right: 0;
    margin-top: 0;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    margin-bottom: 1.75rem;
    list-style-position: outside;
    list-style-image: none;
    list-style: disc;
  }

  ol {
    margin-left: 1.75rem;
    margin-right: 0;
    margin-top: 0;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    margin-bottom: 1.75rem;
    list-style-position: outside;
    list-style-image: none;
  }

  dl {
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    margin-bottom: 1.75rem;
  }

  dd {
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    margin-bottom: 1.75rem;
  }

  p {
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    margin-bottom: 1.75rem;
  }

  figure {
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    margin-bottom: 1.75rem;
  }

  pre {
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    margin-bottom: 1.75rem;
    font-size: 0.85rem;
    line-height: 1.75rem;
  }

  table {
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    margin-bottom: 1.75rem;
    font-size: 1rem;
    line-height: 1.75rem;
    border-collapse: collapse;
    width: 100%;
  }

  fieldset {
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    margin-bottom: 1.75rem;
  }

  blockquote {
    margin-left: -1.75rem;
    margin-right: 1.75rem;
    margin-top: 0;
    padding-bottom: 0;
    padding-left: 1.42188rem;
    padding-right: 0;
    padding-top: 0;
    margin-bottom: 1.75rem;
    font-size: 1.20112rem;
    line-height: 1.75rem;
    color: hsla(0, 0%, 0%, 0.59);
    font-style: italic;
    border-left: 0.32813rem solid hsla(0, 0%, 0%, 0.9);
  }

  form {
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    margin-bottom: 1.75rem;
  }

  noscript {
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    margin-bottom: 1.75rem;
  }

  iframe {
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    margin-bottom: 1.75rem;
  }

  hr {
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    margin-bottom: calc(1.75rem - 1px);
    background: hsla(0, 0%, 0%, 0.2);
    border: none;
    height: 1px;
  }

  address {
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    margin-bottom: 1.75rem;
  }

  b {
    font-weight: 700;
  }

  strong {
    font-weight: 700;
  }

  dt {
    font-weight: 700;
  }

  th {
    font-weight: 700;
  }

  li {
    margin-bottom: calc(1.75rem / 2);
  }

  ol li {
    padding-left: 0;
  }

  ul li {
    padding-left: 0;
  }

  li>ol {
    margin-left: 1.75rem;
    margin-bottom: calc(1.75rem / 2);
    margin-top: calc(1.75rem / 2);
  }

  li>ul {
    margin-left: 1.75rem;
    margin-bottom: calc(1.75rem / 2);
    margin-top: calc(1.75rem / 2);
  }

  blockquote *:last-child {
    margin-bottom: 0;
  }

  li *:last-child {
    margin-bottom: 0;
  }

  p *:last-child {
    margin-bottom: 0;
  }

  li>p {
    margin-bottom: calc(1.75rem / 2);
  }

  code {
    font-size: 0.85rem;
    line-height: 1.75rem;
  }

  kbd {
    font-size: 0.85rem;
    line-height: 1.75rem;
  }

  samp {
    font-size: 0.85rem;
    line-height: 1.75rem;
  }

  abbr {
    border-bottom: 1px dotted hsla(0, 0%, 0%, 0.5);
    cursor: help;
  }

  acronym {
    border-bottom: 1px dotted hsla(0, 0%, 0%, 0.5);
    cursor: help;
  }

  abbr[title] {
    border-bottom: 1px dotted hsla(0, 0%, 0%, 0.5);
    cursor: help;
    text-decoration: none;
  }

  thead {
    text-align: left;
  }

  td,
  th {
    text-align: left;
    border-bottom: 1px solid hsla(0, 0%, 0%, 0.12);
    font-feature-settings: "tnum";
    -moz-font-feature-settings: "tnum";
    -ms-font-feature-settings: "tnum";
    -webkit-font-feature-settings: "tnum";
    padding-left: 1.16667rem;
    padding-right: 1.16667rem;
    padding-top: 0.875rem;
    padding-bottom: calc(0.875rem - 1px);
  }

  th:first-child,
  td:first-child {
    padding-left: 0;
  }

  th:last-child,
  td:last-child {
    padding-right: 0;
  }

  blockquote> :last-child {
    margin-bottom: 0;
  }

  blockquote cite {
    font-size: 1rem;
    line-height: 1.75rem;
    color: hsla(0, 0%, 0%, 0.9);
    font-weight: 400;
  }

  blockquote cite:before {
    content: "— ";
  }

  ul,
  ol {
    margin-left: 0;
  }

  @media only screen and (max-width:480px) {
    ul,
    ol {
      margin-left: 1.75rem;
    }
    blockquote {
      margin-left: -1.3125rem;
      margin-right: 0;
      padding-left: 0.98438rem;
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: 3.5rem;
  }

  a {
    box-shadow: 0 1px 0 0 currentColor;
    color: #007acc;
    text-decoration: none;
  }

  a:hover,
  a:active {
    box-shadow: none;
  }

  mark,
  ins {
    background: #007acc;
    color: white;
    padding: 0.10938rem 0.21875rem;
    text-decoration: none;
  }

  a.gatsby-resp-image-link {
    box-shadow: none;
  }
`
